<template>
    <v-container fluid>
        <template>
            <v-row>
                <v-col cols="6" class="pb-1 pt-0">
                    <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                            :to="item.href"
                            :disabled="item.disabled"
                            >
                            {{ item.text.toUpperCase() }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-col>
                <v-col cols="6" class="pb-1 pt-0 h-100">
                    <office-login></office-login>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="10" class="pt-1">
                    <span class="headline font-weight-medium">Detalle de caja</span>
                </v-col>
                <v-col cols="2" class="pt-1">
                    <v-btn dark 
                        color="success" 
                        width="140" 
                        class="float-right"
                        @click="handleDownloadReport">
                        Descargar XLS
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="1" class="pt-0 pb-0">
                    <span>Fecha</span>
                </v-col>
                <v-col cols="5" class="pt-0 pb-0">
                    <span class="label-purchase">{{ till.operation_date | moment("DD/MM/YYYY") }}</span>
                </v-col>
                <v-col cols="1" class="pt-0 pb-0">
                    <span>V. Yape</span>
                </v-col>
                <v-col cols="2" class="pt-0 pb-0 column-amount">
                    <span class="label-purchase">{{ till.amount_yape | currency('S/') }}</span>
                </v-col>
                <v-col cols="1" class="pt-0 pb-0">
                    <span>M. Apertura</span>
                </v-col>
                <v-col cols="2" class="pt-0 pb-0 column-amount">
                    <span class="label-purchase">{{ till.amount_open | currency('S/') }}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="1" class="pt-0 pb-0">
                    <span>Sucursal</span>
                </v-col>
                <v-col cols="5" class="pt-0 pb-0">
                    <span class="label-purchase">{{ till.office_name }}</span>
                </v-col>
                <v-col cols="1" class="pt-0 pb-0">
                    <span>V. Tarjeta</span>
                </v-col>
                <v-col cols="2" class="pt-0 pb-0 column-amount">
                    <span class="label-purchase">{{ till.amount_card | currency('S/') }}</span>
                </v-col>
                <v-col cols="1" class="pt-0 pb-0">
                    <span>Ventas</span>
                </v-col>
                <v-col cols="2" class="pt-0 pb-0 column-amount">
                    <span class="label-purchase">{{ (parseFloat(till.amount_cash) + parseFloat(till.amount_card) + parseFloat(till.amount_transfer) + parseFloat(till.amount_yape)) - parseFloat(till.amount_canceled) | currency('S/')}}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="1" class="pt-0 pb-0">
                    <span>Caja</span>
                </v-col>
                <v-col cols="5" class="pt-0 pb-0">
                    <span class="label-purchase">{{ till.till_name }}</span>
                </v-col>
                <v-col cols="2" class="pt-0 pb-0">
                    <span>V. Transferencias</span>
                </v-col>
                <v-col cols="1" class="pt-0 pb-0 column-amount">
                    <span class="label-purchase">{{ till.amount_transfer | currency('S/') }}</span>
                </v-col>
                <v-col cols="1" class="pt-0 pb-0">
                    <span>Ingresos</span>
                </v-col>
                <v-col cols="2" class="pt-0 pb-0 column-amount">
                    <span class="label-purchase">{{ till.amount_income | currency('S/') }}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="1" class="pt-0 pb-0">
                    
                </v-col>
                <v-col cols="5" class="pt-0 pb-0">
                    
                </v-col>
                <v-col cols="1" class="pt-0 pb-0">
                    <span>V. Efectivo</span>
                </v-col>
                <v-col cols="2" class="pt-0 pb-0 column-amount">
                    <span class="label-purchase">{{ parseFloat(till.amount_cash) - parseFloat(till.amount_canceled) | currency('S/') }}</span>
                </v-col>
                <v-col cols="1" class="pt-0 pb-0">
                    <span class="label-discount">Egresos</span>
                </v-col>
                <v-col cols="2" class="pt-0 pb-0 column-amount">
                    <span class="label-purchase label-discount">{{ till.amount_expense | currency('S/') }}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="1" class="pt-0 pb-0">
                    
                </v-col>
                <v-col cols="8" class="pt-0 pb-0">
                    
                </v-col>
                <v-col cols="1" class="pt-0 pb-0">
                    <span class="label-discount">Anulaciones</span>
                </v-col>
                <v-col cols="2" class="pt-0 pb-0 column-amount">
                    <span class="label-purchase label-discount">{{ till.amount_canceled | currency('S/') }}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-data-table
                            :headers="headers"
                            :items="detail"
                            :items-per-page="10"
                            class="elevation-1 w-table"
                            height="57vh"
                            fixed-header
                        >
                            <template v-slot:item.operation_date="{item}">
                                <span>{{ item.operation_date | moment("DD/MM/YYYY") }}</span>
                            </template>

                            <template v-slot:item.hour="{item}">
                                <span>{{ item.operation_date | moment("hh:mm a") }}</span>
                            </template>

                            <template v-slot:item.type="{item}">
                                <span :class="'amount-'+item.type">{{ item.type }}</span>
                            </template>

                            <template v-slot:item.user_id="{item}">
                                <span>{{ item.fullname }}</span>
                            </template>

                            <template v-slot:item.amount="{item}">
                                <span  v-if="item.type == 'cierre'" :class="'amount-'+item.type">
                                    {{
                                        getTotalAmountClose(item) | currency('S/')
                                    }}
                                </span>
                                <span v-else :class="'amount-'+item.type">
                                    {{ item.amount | currency('S/') }}
                                </span>
                                
                            </template>

                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </template>
    </v-container>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import router from '@/router/index';
import NumberTools from '../../../helpers/NumberTools';
import OfficeLogin from '../../../components/auth/OfficeLogin';

export default {
    name: 'DetailTill',
    components: {
        OfficeLogin
    },
    data () {
        return {
            itemsBreadcrumbs: [
                {
                    text: 'Reportes',
                    disabled: true,
                    href: '',
                },
                {
                    text: 'Historial de cajas',
                    disabled: false,
                    href: '/app/reportes/reporte-caja',
                }
            ],
            headers: [
                { text: 'FECHA', value: 'operation_date', sortable: false },
                { text: 'HORA', value: 'hour', sortable: false },
                { text: 'T. DE MOVIMIENTO', value: 'type', sortable: false },
                { text: 'USUARIO', value: 'user_id', sortable: false },                
                { text: 'DESCRIPCIÓN', value: 'description', sortable: false },
                { text: 'BANCO', value: 'bank_description', sortable: false },
                { text: 'MONTO', value: 'amount', sortable: false, align: 'end' },                
            ],
        }
    },
    computed: {
        ...mapState('report-tills', [
            'till',
            'detail',
            'file_excel'
        ])
    },
    methods: {
        ...mapActions('report-tills', [
            'getTillDetail',
            'tillsDetailExcel'
        ]),
        async handleDownloadReport () {
            await this.tillsDetailExcel({
                till_summary_id: this.till.id,
                till_id: this.till.till_id,
                date: this.till.operation_date,
                till_selected: this.till
            });
            if (this.file_excel) {
                let url = process.env.VUE_APP_API_URL_BASE + '/' + this.file_excel;
                window.open(url);
            }
        },
        getTotalAmountClose (item) {
            let total = NumberTools.getNumberDecimalValue(item.amount_card) +
                        NumberTools.getNumberDecimalValue(item.amount) +
                        NumberTools.getNumberDecimalValue(item.amount_transfer) +
                        NumberTools.getNumberDecimalValue(item.amount_yape);

            return total;
        }
    },
    created () {
        this.getTillDetail({
            till_summary_id: this.till.id,
            till_id: this.till.till_id,
            date: this.till.operation_date,
            till_selected: this.till
        });
    }
}
</script>

<style scoped>
.column-amount{
    text-align: right;
}

.label-purchase{
    font-weight: 600;
}

.label-discount{
    color: #F44336;
}

.amount-apertura{
    font-weight: 600;
    color: #4CAF50;
}

.amount-ingreso{
    font-weight: 600;
    color: #50be9f;
}

.amount-egreso{
    font-weight: 600;
    color: #ff4d4f;
}

.amount-efectivo{
    font-weight: 600;
    color: #3F51B5;
}

.amount-tarjeta{
    font-weight: 600;
    color: #2196F3;
}

.amount-cierre{
    font-weight: 600;
    color: #023145;
}

.amount-yape{
    font-weight: 600;
    color: #742284;
}

.amount-transferencia{
    font-weight: 600;
    color: #ff9900; 
}

.amount-anulacion{
    font-weight: 600;
    color: #B71C1C; 
}
</style>